import styled from "styled-components";

/**
 * Styled components
 */
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.div`
  flex: 1;
`;

export { AppContainer, MainContent };
