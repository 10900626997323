// src/Routes.tsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivacyPolicy from "../screens/Policies/PrivacyPolicy";
import TermsAndConditions from "../screens/Policies/TermsAndConditions";
import CookiePolicy from "../screens/Policies/CookiePolicy";
import Home from "../screens/Home";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
    </Routes>
  );
};

export default AppRoutes;
