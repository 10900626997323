// src/App.tsx
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "../src/routes/Routes";
import Footer from "./components/Footer";
import { AppContainer, MainContent } from "./styles";

const App: React.FC = () => {
  return (
    <Router>
      <AppContainer>
        <MainContent>
          <AppRoutes />
        </MainContent>
        <Footer />
      </AppContainer>
    </Router>
  );
};

export default App;
