import React from "react";
import { MainContainer, Title, SubTitle, Content, Link } from "../styles"; // Assuming these styled components are in a file named 'StyledComponents.js'

const TermsAndConditions: React.FC = () => {
  return (
    <MainContainer>
      <Title>TÉRMINOS DE USO</Title>
      <SubTitle>
        Bienvenido a Petmate, operado por Petmate (“nosotros,” “nos,” la
        “Compañía” o “Petmate”).
      </SubTitle>

      <SubTitle>Aceptación del Acuerdo de Términos de Uso</SubTitle>
      <Content>
        Al crear una cuenta de Petmate o al utilizar cualquier servicio de
        Petmate, ya sea a través de un dispositivo móvil, aplicación móvil o
        computadora (colectivamente, el “Servicio”), usted acepta estar sujeto a
        (i) estos Términos de Uso, (ii) nuestra{" "}
        <Link href="/privacy-policy">política de privacidad</Link> y consejos de
        seguridad, cada uno de los cuales se incorpora por referencia en este
        Acuerdo, y (iii) cualquier término que se le haya comunicado si adquiere
        o ha adquirido características, productos o servicios adicionales que
        ofrecemos en el Servicio (colectivamente, este “Acuerdo”). Si no acepta
        y acuerda estar sujeto a todos los términos de este Acuerdo (excepto por
        el derecho limitado de exclusión voluntaria para ciertos usuarios
        establecido en la Sección 15), no debe utilizar el Servicio.
      </Content>

      <Content>
        Podemos hacer cambios a este Acuerdo y al Servicio de vez en cuando.
        Podemos hacerlo por una variedad de razones, incluidas para reflejar
        cambios en o requisitos de la ley, nuevas funciones o cambios en las
        prácticas comerciales. La versión más reciente de este Acuerdo se
        publicará en el Servicio en Configuración debe revisar regularmente la
        versión más reciente. La versión más reciente es la que se aplica. Si
        los cambios incluyen cambios materiales que afectan sus derechos u
        obligaciones, le notificaremos con anticipación sobre los cambios por
        medios razonables, lo que podría incluir notificación a través del
        Servicio o por correo electrónico. Si continúa utilizando el Servicio
        después de que los cambios entren en vigor, entonces acepta el Acuerdo
        revisado. Usted acepta que este Acuerdo reemplazará cualquier acuerdo
        previo (excepto como se indica específicamente en el presente) y regirá
        toda su relación con Petmate, incluidos, entre otros, eventos, acuerdos
        y conductas anteriores a su aceptación de este Acuerdo.
      </Content>

      <SubTitle>Elegibilidad</SubTitle>
      <Content>
        Debe tener al menos 18 años de edad para crear una cuenta en Petmate y
        utilizar el Servicio. Al crear una cuenta y utilizar el Servicio, usted
        declara y garantiza que:
      </Content>

      <Content>• Puede celebrar un contrato vinculante con Petmate.</Content>
      <Content>
        • No es una persona a la que se le haya prohibido el uso del Servicio
        según las leyes de Canadá o de cualquier otra jurisdicción aplicable.
      </Content>
      <Content>
        • Cumplirá con este Acuerdo y con todas las leyes, normas y regulaciones
        locales, estatales, nacionales e internacionales aplicables.
      </Content>
      <Content>
        • Nunca ha sido condenado o se ha declarado culpable sin oposición de un
        delito grave, un delito sexual o cualquier delito relacionado con la
        violencia, y que no está obligado a registrarse como delincuente sexual
        en ningún registro estatal, federal o local de delincuentes sexuales.
      </Content>

      <SubTitle>Tu cuenta</SubTitle>
      <Content>
        Para utilizar Petmate, puede iniciar sesión utilizando su cuenta de
        Facebook o Google. Si lo hace, nos autoriza a acceder y utilizar cierta
        información de su cuenta de Facebook o Google, incluyendo, pero no
        limitado a, su perfil público y la información sobre sus amigos que
        comparte en común con otros usuarios de Petmate. Para obtener más
        información sobre la información que recopilamos de usted y cómo la
        utilizamos, consulte nuestra{" "}
        <Link href="/privacy-policy">política de privacidad</Link>.
      </Content>

      <Content>
        Usted es responsable de mantener la confidencialidad de las credenciales
        de inicio de sesión que utiliza para registrarse en Petmate, y es el
        único responsable de todas las actividades que ocurran bajo esas
        credenciales. Si cree que alguien ha accedido a su cuenta, por favor{" "}
        <Link href="mailto:petmateapp24@gmail.com">contáctenos</Link> de
        inmediato.
      </Content>

      <SubTitle>Modificación del Servicio y Terminación</SubTitle>
      <Content>
        Petmate siempre se esfuerza por mejorar el Servicio y ofrecerle
        funcionalidades adicionales que encuentre atractivas y útiles. Esto
        significa que podemos agregar nuevas características o mejoras de
        productos de vez en cuando, así como eliminar algunas funciones, y si
        estas acciones no afectan materialmente sus derechos u obligaciones, es
        posible que no le notifiquemos antes de realizarlas. Incluso podemos
        suspender el Servicio por completo, en cuyo caso le notificaremos con
        anticipación a menos que circunstancias atenuantes, como preocupaciones
        de seguridad, nos impidan hacerlo. Usted puede terminar su cuenta en
        cualquier momento, por cualquier motivo, tocando el botón “Eliminar
        Cuenta” ubicado en la configuración del usuario. Eliminar su cuenta
        individual eliminará de manera permanente todos los datos e información
        asociados con el perfil del usuario. Una vez eliminada, no podrá ser
        recuperada ni por el usuario ni por Petmate. Los usuarios también pueden
        enviar un correo electrónico directamente a soporte en{" "}
        <Link href="mailto:petmateapp24@gmail.com">petmateapp24@gmail.com</Link>{" "}
        para solicitar la eliminación de datos, en cuyo caso todos sus datos
        serán eliminados manualmente de todos los archivos y bases de datos de
        Petmate. Cabe señalar, sin embargo, que si utiliza una cuenta de pago de
        terceros, deberá gestionar las compras dentro de la aplicación a través
        de dicha cuenta (por ejemplo, iTunes, Google Play) para evitar
        facturaciones adicionales. Petmate puede terminar su cuenta en cualquier
        momento sin previo aviso si cree que ha violado este Acuerdo. Tras dicha
        terminación, no tendrá derecho a ningún reembolso por compras. Después
        de que su cuenta sea terminada, este Acuerdo finalizará, excepto que las
        siguientes disposiciones seguirán aplicándose a usted y a Petmate:
        Sección 4, Sección 5, y las Secciones 12 a 19.
      </Content>

      <SubTitle>
        Seguridad; Sus Interacciones con Otros Usuarios y sus Mascotas
      </SubTitle>
      <Content>
        Aunque Petmate se esfuerza por fomentar una experiencia de usuario
        respetuosa a través de características como el doble consentimiento, que
        solo permite a los usuarios comunicarse si ambos han mostrado interés
        mutuo, no es responsable de la conducta de ningún usuario ni de sus
        mascotas, ya sea dentro o fuera del Servicio. Usted acepta tener
        precaución en todas las interacciones con otros usuarios y sus mascotas,
        y entiende que algunos animales pueden ser volátiles. Ningún animal debe
        ser acercado sin el permiso del propietario. Usted acepta utilizar el
        MAYOR cuidado al interactuar con animales que no conoce y a velar por su
        propia mascota en la medida de sus capacidades. No somos responsables
        del bienestar, seguridad o cuidado de su mascota bajo ninguna
        circunstancia. Todo lo anterior es especialmente aplicable si decide
        comunicarse fuera del Servicio o reunirse en persona. Use buen juicio en
        todo momento y siempre sea prudente al conocer personas y animales.
        Además, acepta revisar y seguir los Consejos de Seguridad de Petmate
        antes de utilizar el Servicio y mantenerse al día con los más recientes
        Consejos de Seguridad en todo momento. Usted acepta que no proporcionará
        su información financiera (por ejemplo, su tarjeta de crédito o
        información de cuenta bancaria), ni enviará dinero a otros usuarios. Al
        aceptar nuestros términos de uso, acepta que no somos responsables en
        caso de cualquier incidente, independientemente de las circunstancias.
      </Content>

      <Content>
        USTED ES EL ÚNICO RESPONSABLE DE SUS INTERACCIONES CON OTROS USUARIOS Y
        SUS MASCOTAS. USTED ENTIENDE QUE Petmate NO REALIZA VERIFICACIONES DE
        ANTECEDENTES PENALES A SUS USUARIOS, VERIFICACIONES DE COMPORTAMIENTO
        PARA SUS MASCOTAS, NI INVESTIGA EL PASADO DE SUS USUARIOS. Petmate NO
        HACE DECLARACIONES NI GARANTÍAS SOBRE LA CONDUCTA DE LOS USUARIOS.
        Petmate SE RESERVA EL DERECHO DE REALIZAR – Y USTED ACEPTA QUE Petmate
        PUEDE REALIZAR – CUALQUIER VERIFICACIÓN DE ANTECEDENTES PENALES U OTROS
        EXÁMENES (COMO BÚSQUEDAS EN REGISTROS DE DELINCUENTES SEXUALES) EN
        CUALQUIER MOMENTO UTILIZANDO REGISTROS PÚBLICOS DISPONIBLES.
      </Content>

      <SubTitle>Derechos que Petmate le Otorga</SubTitle>
      <Content>
        Petmate le otorga una licencia personal, mundial, libre de regalías, no
        asignable, no exclusiva, revocable y no sublicenciable para acceder y
        utilizar el Servicio. Esta licencia tiene el único propósito de
        permitirle usar y disfrutar de los beneficios del Servicio según lo
        previsto por Petmate y permitido por este Acuerdo. Por lo tanto, usted
        acepta no:
      </Content>

      <Content>
        • Utilizar el Servicio o cualquier contenido contenido en el Servicio
        para fines comerciales sin nuestro consentimiento por escrito.
      </Content>
      <Content>
        • Copiar, modificar, transmitir, crear trabajos derivados de, utilizar o
        reproducir de cualquier manera cualquier material con derechos de autor,
        imágenes, marcas comerciales, nombres comerciales, marcas de servicio u
        otra propiedad intelectual, contenido o información de propiedad
        accesible a través del Servicio sin el consentimiento previo por escrito
        de Petmate.
      </Content>
      <Content>
        • Expresar o implicar que cualquier declaración que haga es respaldada
        por Petmate.
      </Content>
      <Content>
        • Utilizar cualquier robot, bot, araña, rastreador, raspador, aplicación
        de búsqueda/recuperación de sitios, proxy u otro dispositivo, método o
        proceso manual o automático para acceder, recuperar, indexar, “extraer
        datos” o de cualquier manera reproducir o eludir la estructura de
        navegación o la presentación del Servicio o su contenido.
      </Content>
      <Content>
        • Utilizar el Servicio de cualquier manera que pueda interferir,
        interrumpir o afectar negativamente el Servicio o los servidores o redes
        conectados al Servicio.
      </Content>
      <Content>
        • Subir virus u otro código malicioso o comprometer de otra manera la
        seguridad del Servicio.
      </Content>
      <Content>
        • Falsificar encabezados o manipular de otro modo identificadores para
        disfrazar el origen de cualquier información transmitida hacia o a
        través del Servicio.
      </Content>
      <Content>
        • “Enmarcar” o “reflejar” cualquier parte del Servicio sin la
        autorización previa por escrito de Petmate.
      </Content>
      <Content>
        • Utilizar etiquetas meta o código u otros dispositivos que contengan
        cualquier referencia a Petmate o al Servicio (o cualquier marca
        comercial, nombre comercial, marca de servicio, logotipo o eslogan de
        Petmate) para dirigir a cualquier persona a cualquier otro sitio web
        para cualquier propósito.
      </Content>
      <Content>
        • Modificar, adaptar, sublicenciar, traducir, vender, realizar
        ingeniería inversa, descifrar, descompilar o desensamblar de cualquier
        manera cualquier parte del Servicio, o causar que otros lo hagan.
      </Content>
      <Content>
        • Utilizar o desarrollar cualquier aplicación de terceros que interactúe
        con el Servicio o el Contenido o información de otros usuarios sin
        nuestro consentimiento por escrito.
      </Content>
      <Content>
        • Usar, acceder o publicar la interfaz de programación de aplicaciones
        de Petmate sin nuestro consentimiento por escrito.
      </Content>
      <Content>
        • Sondear, escanear o probar la vulnerabilidad de nuestro Servicio o
        cualquier sistema o red.
      </Content>
      <Content>
        • Fomentar o promover cualquier actividad que viole este Acuerdo.
      </Content>

      <Content>
        La Compañía puede investigar y tomar cualquier acción legal disponible
        en respuesta a usos ilegales y/o no autorizados del Servicio, incluida
        la terminación de su cuenta. Cualquier software que le proporcionemos
        puede descargar e instalar automáticamente actualizaciones, mejoras u
        otras características nuevas. Puede ajustar estas descargas automáticas
        a través de la configuración de su dispositivo.
      </Content>

      <SubTitle>Derechos que Usted Otorga a Petmate</SubTitle>
      <Content>
        Al crear una cuenta, usted otorga a Petmate un derecho y una licencia
        mundial, sublicenciable, libre de regalías, para alojar, almacenar,
        usar, copiar, mostrar, reproducir, adaptar, editar, publicar, modificar
        y distribuir la información que nos autoriza a acceder desde su cuenta
        de Petmate, así como cualquier información que publique, cargue, muestre
        o de otra manera ponga a disposición (colectivamente, “publicar”) en el
        Servicio o transmita a otros usuarios (colectivamente, “Contenido”). La
        licencia de Petmate sobre su Contenido será no exclusiva, excepto que la
        licencia de Petmate será exclusiva con respecto a los trabajos derivados
        creados a través del uso del Servicio. Por ejemplo, Petmate tendría una
        licencia exclusiva sobre capturas de pantalla del Servicio que incluyan
        su Contenido. Además, para que Petmate pueda evitar el uso de su
        Contenido fuera del Servicio, usted autoriza a Petmate a actuar en su
        nombre con respecto a usos infractores de su Contenido tomados del
        Servicio por otros usuarios o terceros. Esto incluye expresamente la
        autoridad, pero no la obligación, de enviar avisos de conformidad con 17
        U.S.C. § 512(c)(3) (es decir, Avisos de Retirada de DMCA) en su nombre
        si su Contenido es tomado y utilizado por terceros fuera del Servicio.
        Nuestra licencia sobre su Contenido está sujeta a sus derechos bajo la
        ley aplicable (por ejemplo, leyes sobre protección de datos personales
        en la medida en que cualquier Contenido contenga información personal
        tal como se define en esas leyes) y tiene el propósito limitado de
        operar, desarrollar, proporcionar y mejorar el Servicio, así como
        investigar y desarrollar nuevos servicios.
      </Content>

      <Content>
        Usted acepta que cualquier Contenido que coloque o que nos autorice a
        colocar en el Servicio puede ser visto por otros usuarios y puede ser
        visto por cualquier persona que visite o participe en el Servicio (como
        personas que puedan recibir Contenido compartido de otros usuarios de
        Petmate). Usted acepta que toda la información que envíe al crear su
        cuenta, incluida la información enviada desde su cuenta de Facebook, es
        precisa y veraz y que tiene el derecho de publicar el Contenido en el
        Servicio y otorgar la licencia a Petmate mencionada anteriormente. Usted
        entiende y acepta que podemos monitorear o revisar cualquier Contenido
        que publique como parte de un Servicio. Podemos eliminar cualquier
        Contenido, en su totalidad o en parte, que a nuestro exclusivo juicio
        viole este Acuerdo o pueda dañar la reputación del Servicio. Al
        comunicarse con nuestros representantes de atención al cliente, usted
        acepta ser respetuoso y amable. Si consideramos que su comportamiento
        hacia cualquiera de nuestros representantes de atención al cliente u
        otros empleados es en cualquier momento amenazante u ofensivo, nos
        reservamos el derecho de terminar inmediatamente su cuenta.
      </Content>

      <Content>
        En consideración a que Petmate le permita utilizar el Servicio, usted
        acepta que nosotros, nuestros afiliados y nuestros socios externos
        puedan colocar publicidad en el Servicio. Al enviar sugerencias o
        comentarios a Petmate sobre nuestro Servicio, usted acepta que Petmate
        pueda usar y compartir dichos comentarios para cualquier propósito sin
        compensarlo. Usted acepta que Petmate pueda acceder, preservar y
        divulgar la información de su cuenta y Contenido si es requerido por la
        ley o en la creencia de buena fe de que dicho acceso, preservación o
        divulgación es razonablemente necesario, como para: (i) cumplir con
        procesos legales; (ii) hacer cumplir este Acuerdo; (iii) responder a
        reclamos de que cualquier Contenido viola los derechos de terceros; (iv)
        responder a sus solicitudes de servicio al cliente; o (v) proteger los
        derechos, la propiedad o la seguridad personal de la Compañía o de
        cualquier otra persona.
      </Content>

      <SubTitle>Reglas de la Comunidad</SubTitle>
      <Content>Al utilizar el Servicio, usted acepta que no:</Content>

      <Content>
        • Utilizará el Servicio para cualquier propósito que sea ilegal o esté
        prohibido por este Acuerdo.
      </Content>
      <Content>
        • Utilizará el Servicio para cualquier propósito dañino o nefasto.
      </Content>
      <Content>• Utilizará el Servicio para dañar a Petmate.</Content>
      <Content>
        • Violará nuestras directrices comunitarias, actualizadas de vez en
        cuando.
      </Content>
      <Content>
        • Enviará spam, solicitará dinero o defraudará a cualquier usuario o sus
        mascotas.
      </Content>
      <Content>
        • Se hará pasar por cualquier persona o entidad o publicará imágenes de
        otra persona o sus mascotas sin su permiso.
      </Content>
      <Content>
        • Acosará, “perseguirá,” intimidará, agredirá, acosará, maltratará o
        difamará a cualquier persona o mascota.
      </Content>
      <Content>
        • Publicará cualquier Contenido que viole o infrinja los derechos de
        alguien, incluidos los derechos de publicidad, privacidad, derechos de
        autor, marcas registradas u otros derechos de propiedad intelectual o
        derechos contractuales.
      </Content>
      <Content>
        • Publicará cualquier Contenido que sea discurso de odio, amenazante,
        sexualmente explícito o pornográfico; incite a la violencia; o contenga
        desnudez o violencia gráfica o gratuita.
      </Content>
      <Content>
        • Publicará cualquier Contenido que promueva el racismo, la
        intolerancia, el odio o el daño físico de cualquier tipo contra
        cualquier grupo, individuo o animal.
      </Content>
      <Content>
        • Solicitará contraseñas para cualquier propósito, o información de
        identificación personal con fines comerciales o ilegales de otros
        usuarios o difundirá la información personal de otra persona sin su
        permiso.
      </Content>
      <Content>
        • Utilizará la cuenta de otro usuario, compartirá una cuenta con otro
        usuario o mantendrá más de una cuenta.
      </Content>
      <Content>
        • Creará otra cuenta si ya hemos terminado su cuenta, a menos que tenga
        nuestro permiso.
      </Content>

      <Content>
        Petmate se reserva el derecho de investigar y/o terminar su cuenta sin
        reembolso de cualquier compra si ha violado este Acuerdo, ha hecho un
        uso indebido del Servicio o ha actuado de una manera que Petmate
        considere inapropiada o ilegal, incluidas acciones o comunicaciones que
        ocurran dentro o fuera del Servicio.
      </Content>

      <SubTitle>Contenido de Otros Usuarios</SubTitle>
      <Content>
        Aunque Petmate se reserva el derecho de revisar y eliminar Contenido que
        viole este Acuerdo, dicho Contenido es responsabilidad exclusiva del
        usuario que lo publica, y Petmate no puede garantizar que todo el
        Contenido cumpla con este Acuerdo. Si ve Contenido en el Servicio que
        viole este Acuerdo, por favor, repórtelo dentro del Servicio o a través
        de nuestro formulario de contacto.
      </Content>

      <SubTitle>Compras</SubTitle>
      <Content>
        Generalidades. De vez en cuando, Petmate puede ofrecer productos y
        servicios para su compra (“compras dentro de la aplicación”) a través de
        iTunes, Google Play, facturación del operador, facturación directa de
        Petmate u otras plataformas de pago autorizadas por Petmate. Si elige
        realizar una compra dentro de la aplicación, se le pedirá que confirme
        su compra con el proveedor de pago correspondiente, y su método de pago
        (ya sea su tarjeta o una cuenta de terceros como Google Play o iTunes)
        (su “Método de Pago”) será cargado a los precios que se le muestren por
        el(los) servicio(s) que haya seleccionado, así como cualquier impuesto
        sobre las ventas u otros impuestos similares que puedan ser impuestos
        sobre sus pagos, y usted autoriza a Petmate o a la cuenta de terceros,
        según corresponda, a cobrarle.
      </Content>

      <Content>
        Renovación Automática. Si compra una suscripción periódica con
        renovación automática, su Método de Pago continuará siendo cobrado por
        la suscripción hasta que la cancele. Después de su período inicial de
        suscripción, y nuevamente después de cualquier período de suscripción
        subsiguiente, su suscripción continuará automáticamente por un período
        equivalente adicional, al precio que aceptó al suscribirse. Si no desea
        que su suscripción se renueve automáticamente, o si desea cambiar o
        cancelar su suscripción, deberá iniciar sesión en su cuenta de terceros
        (o en Configuración de la Cuenta en Petmate) y seguir las instrucciones
        para cancelar su suscripción, incluso si ha eliminado su cuenta con
        nosotros o si ha eliminado la aplicación Petmate de su dispositivo.
        Eliminar su cuenta en Petmate o eliminar la aplicación Petmate de su
        dispositivo no cancela su suscripción; Petmate retendrá todos los fondos
        cargados a su Método de Pago hasta que cancele su suscripción en Petmate
        o en la cuenta de terceros, según corresponda. Si cancela su
        suscripción, puede usar su suscripción hasta el final de su término de
        suscripción actual, y su suscripción no se renovará después de que
        expire su término actual.
      </Content>

      <Content>
        Términos Adicionales que se aplican si paga a Petmate directamente con
        su Método de Pago. Si paga a Petmate directamente, Petmate puede
        corregir cualquier error o equivocación en la facturación que cometa,
        incluso si ya ha solicitado o recibido el pago. Si inicia una reversión
        de cargo o de alguna manera revierte un pago realizado con su Método de
        Pago, Petmate puede terminar su cuenta inmediatamente a su entera
        discreción.
      </Content>

      <Content>
        Puede editar la información de su Método de Pago visitando Petmate y
        yendo a “Mi Perfil”. Si un pago no se liquida con éxito, debido a
        vencimiento, fondos insuficientes, u otros motivos, y no edita la
        información de su Método de Pago o cancela su suscripción, sigue siendo
        responsable de cualquier cantidad no cobrada y nos autoriza a seguir
        facturando el Método de Pago, según se actualice. Esto puede resultar en
        un cambio en sus fechas de facturación de pago. Además, nos autoriza a
        obtener fechas de vencimiento actualizadas o de reemplazo y números de
        tarjeta para su tarjeta de crédito o débito proporcionados por su emisor
        de tarjeta de crédito o débito. Los términos de su pago se basarán en su
        Método de Pago y pueden estar determinados por acuerdos entre usted y la
        institución financiera, emisor de tarjeta de crédito u otro proveedor de
        su Método de Pago elegido. Si reside fuera de las Américas, acepta que
        su pago a Petmate se realizará a través de MTCH Technology Services
        Limited.
      </Content>

      <Content>
        Artículos Virtuales. De vez en cuando, puede comprar una licencia
        limitada, personal, no transferible, no sublicenciable, revocable para
        usar “artículos virtuales”. Cualquier saldo de Artículo Virtual mostrado
        en su cuenta no constituye un saldo en el mundo real ni refleja ningún
        valor almacenado, sino que constituye una medida de la extensión de su
        licencia. Los Artículos Virtuales no incurren en tarifas por no uso; sin
        embargo, la licencia que se le otorga en los Artículos Virtuales
        terminará de acuerdo con los términos de este Acuerdo, cuando Petmate
        deje de proporcionar el Servicio o su cuenta sea cerrada o terminada.
        Petmate, a su sola discreción, se reserva el derecho de cobrar tarifas
        por el derecho a acceder o usar Artículos Virtuales y/o puede distribuir
        Artículos Virtuales con o sin cargo. Petmate puede gestionar, regular,
        controlar, modificar o eliminar Artículos Virtuales en cualquier
        momento. Petmate no tendrá ninguna responsabilidad ante usted o terceros
        en caso de que Petmate ejerza tales derechos. Los Artículos Virtuales
        solo pueden ser canjeados a través del Servicio. TODAS LAS COMPRAS Y
        CANJES DE ARTÍCULOS VIRTUALES REALIZADOS A TRAVÉS DEL SERVICIO SON
        FINALES Y NO REEMBOLSABLES. La provisión de Artículos Virtuales para su
        uso en el Servicio es un servicio que comienza inmediatamente después de
        la aceptación de su compra de dichos Artículos Virtuales. USTED RECONOCE
        QUE Petmate NO ESTÁ OBLIGADO A PROPORCIONAR UN REEMBOLSO POR NINGUNA
        RAZÓN, Y QUE NO RECIBIRÁ DINERO NI OTRA COMPENSACIÓN POR LOS ARTÍCULOS
        VIRTUALES NO UTILIZADOS CUANDO UNA CUENTA SE CIERRE, YA SEA DICHO CIERRE
        VOLUNTARIO O INVOLUNTARIO.
      </Content>

      <Content>
        Reembolsos. En general, todos los cargos por compras no son
        reembolsables, y no hay reembolsos ni créditos por períodos parcialmente
        utilizados. Podemos hacer una excepción si se solicita un reembolso por
        una oferta de suscripción dentro de los catorce días posteriores a la
        fecha de la transacción, o si las leyes aplicables en su jurisdicción
        prevén reembolsos.
      </Content>

      <Content>Para solicitar un reembolso:</Content>

      <Content>
        Si se suscribió usando su cuenta de Google Play Store o directamente a
        través de Petmate: por favor contacte a soporte al cliente con su número
        de pedido de Google Play Store (puede encontrar el número de pedido en
        el correo electrónico de confirmación del pedido o iniciando sesión en
        Google Wallet) o de Petmate (puede encontrarlo en su correo electrónico
        de confirmación). También puede enviar o entregar un aviso firmado y
        fechado que indique que usted, el comprador, está cancelando este
        acuerdo, o palabras de efecto similar. Incluya también la dirección de
        correo electrónico o el número de teléfono asociado con su número de
        pedido. Este aviso deberá ser enviado a:{" "}
        <Link href="mailto:petmateapp24@gmail.com">petmateapp24@gmail.com</Link>
        .
      </Content>

      <SubTitle>
        Aviso y Procedimiento para Presentar Reclamos de Infracción de Derechos
        de Autor
      </SubTitle>
      <Content>
        Si cree que su obra ha sido copiada y publicada en el Servicio de una
        manera que constituye una infracción de derechos de autor, por favor
        proporcione a nuestro Agente de Derechos de Autor la siguiente
        información:
      </Content>

      <Content>
        • Una firma electrónica o física de la persona autorizada para actuar en
        nombre del titular del derecho de autor.
      </Content>
      <Content>
        • Una descripción de la obra protegida por derechos de autor que usted
        afirma ha sido infringida.
      </Content>
      <Content>
        • Una descripción de dónde se encuentra el material que usted afirma
        está infringiendo en el Servicio (y dicha descripción debe ser
        razonablemente suficiente para permitirnos encontrar el material
        presuntamente infractor).
      </Content>
      <Content>
        • Su información de contacto, incluyendo dirección, número de teléfono y
        correo electrónico.
      </Content>
      <Content>
        • Una declaración escrita por usted de que tiene una creencia de buena
        fe de que el uso en disputa no está autorizado por el titular de los
        derechos de autor, su agente o la ley.
      </Content>
      <Content>
        • Una declaración hecha bajo pena de perjurio de que la información
        anterior en su aviso es precisa y que usted es el titular de los
        derechos de autor o está autorizado para actuar en nombre del titular de
        los derechos de autor.
      </Content>

      <Content>
        El aviso de reclamos de infracción de derechos de autor debe ser
        proporcionado al Agente de Derechos de Autor de la Compañía por correo
        electrónico a{" "}
        <Link href="mailto:petmateapp24@gmail.com">petmateapp24@gmail.com</Link>{" "}
        o por teléfono al +52-331-879-0680.
      </Content>

      <Content>
        Petmate terminará las cuentas de infractores reincidentes.
      </Content>

      <SubTitle>Descargos de Responsabilidad</SubTitle>
      <Content>
        Petmate PROPORCIONA EL SERVICIO “TAL COMO ESTÁ” Y “SEGÚN DISPONIBILIDAD”
        Y, EN LA MEDIDA PERMITIDA POR LA LEY APLICABLE, NO OTORGA NINGUNA
        GARANTÍA DE NINGÚN TIPO, YA SEA EXPRESA, IMPLÍCITA, LEGAL O DE OTRO TIPO
        CON RESPECTO AL SERVICIO (INCLUYENDO TODO EL CONTENIDO QUE SE CONTIENE
        EN ÉL), INCLUYENDO, SIN LIMITACIÓN, CUALQUIER GARANTÍA IMPLÍCITA DE
        CALIDAD SATISFACTORIA, COMERCIABILIDAD, ADECUACIÓN PARA UN PROPÓSITO
        PARTICULAR O NO INFRACCIÓN. Petmate NO DECLARA NI GARANTIZA QUE (A) EL
        SERVICIO SERÁ ININTERRUMPIDO, SEGURO O LIBRE DE ERRORES, (B) CUALQUIER
        DEFECTO O ERROR EN EL SERVICIO SERÁ CORREGIDO, O (C) QUE CUALQUIER
        CONTENIDO O INFORMACIÓN QUE OBTENGA EN O A TRAVÉS DEL SERVICIO SERÁ
        PRECISA. Petmate NO SE HACE RESPONSABLE DE NINGÚN CONTENIDO QUE USTED U
        OTRO USUARIO O TERCERO PUBLIQUE, ENVÍE O RECIBA A TRAVÉS DEL SERVICIO.
        CUALQUIER MATERIAL DESCARGADO O OBTENIDO DE OTRO MODO MEDIANTE EL USO
        DEL SERVICIO SE ACCEDE BAJO SU PROPIA DISCRECIÓN Y RIESGO. Petmate
        RENUNCIA Y NO SE HACE RESPONSABLE DE NINGUNA CONDUCTA DE USTED, SU
        MASCOTA O CUALQUIER OTRO USUARIO, O SU MASCOTA, DENTRO O FUERA DEL
        SERVICIO.
      </Content>

      <SubTitle>Servicios de Terceros</SubTitle>
      <Content>
        El Servicio puede contener anuncios y promociones ofrecidos por
        terceros, así como enlaces a otros sitios web o recursos. Petmate no es
        responsable de la disponibilidad (o falta de disponibilidad) de dichos
        sitios web o recursos externos. Si decide interactuar con los terceros
        disponibles a través de nuestro Servicio, los términos de dicha parte
        regirán su relación con usted. Petmate no es responsable ni está
        obligado por los términos o acciones de dichos terceros.
      </Content>

      <SubTitle>Limitación de Responsabilidad</SubTitle>
      <Content>
        EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY APLICABLE, EN NINGÚN CASO
        Petmate, SUS AFILIADOS, EMPLEADOS, LICENCIANTES O PROVEEDORES DE
        SERVICIOS SERÁN RESPONSABLES POR DAÑOS INDIRECTOS, CONSECUENCIALES,
        EJEMPLARES, INCIDENTALES, ESPECIALES, PUNITIVOS O AGRAVADOS, INCLUYENDO,
        SIN LIMITACIÓN, PÉRDIDA DE BENEFICIOS, YA SEA INCURRIDA DIRECTAMENTE O
        INDIRECTAMENTE, O CUALQUIER PÉRDIDA DE DATOS, USO, BUENA VOLUNTAD U
        OTRAS PÉRDIDAS INTANGIBLES, RESULTANTES DE: (I) SU ACCESO O USO O
        INCAPACIDAD DE ACCEDER O UTILIZAR EL SERVICIO; (II) LA CONDUCTA O
        CONTENIDO DE OTROS USUARIOS O TERCEROS EN, A TRAVÉS DE, O DESPUÉS DEL
        USO DEL SERVICIO; O (III) ACCESO NO AUTORIZADO, USO O ALTERACIÓN DE SU
        CONTENIDO, INCLUSO SI Petmate HA SIDO ADVERTIDO EN CUALQUIER MOMENTO DE
        LA POSIBILIDAD DE DICHOS DAÑOS. SIN PERJUICIO DE LO ANTERIOR, EN NINGÚN
        CASO LA RESPONSABILIDAD AGREGADA DE Petmate HACIA USTED POR CUALQUIER Y
        TODAS LAS RECLAMACIONES QUE SURJAN O SE RELACIONEN CON EL SERVICIO O
        ESTE ACUERDO EXCEDERÁ LA CANTIDAD PAGADA, SI LA HUBIERA, POR USTED A
        Petmate DURANTE EL PERÍODO DE VEINTICUATRO (24) MESES INMEDIATAMENTE
        ANTERIOR A LA FECHA EN QUE USTED PRESENTE POR PRIMERA VEZ UNA DEMANDA,
        ARBITRAJE O CUALQUIER OTRO PROCEDIMIENTO LEGAL CONTRA Petmate, YA SEA EN
        DERECHO O EN EQUIDAD, EN CUALQUIER TRIBUNAL. LA LIMITACIÓN DE DAÑOS
        ESTABLECIDA EN LA ORACIÓN INMEDIATAMENTE ANTERIOR SE APLICA (i)
        INDEPENDIENTEMENTE DEL FUNDAMENTO EN EL QUE SE BASE LA RESPONSABILIDAD
        (YA SEA POR DEFECTO, CONTRATO, AGRAVIO, ESTATUTO O DE OTRO MODO), (ii)
        INDEPENDIENTEMENTE DEL TIPO DE INCUMPLIMIENTO DE OBLIGACIONES, Y (iii)
        CON RESPECTO A TODOS LOS EVENTOS, EL SERVICIO Y ESTE ACUERDO.
      </Content>

      <Content>
        LAS DISPOSICIONES DE LIMITACIÓN DE RESPONSABILIDAD ESTABLECIDAS EN ESTA
        SECCIÓN 14 SE APLICARÁN INCLUSO SI SUS REMEDIOS EN VIRTUD DE ESTE
        ACUERDO NO CUMPLEN CON SU PROPÓSITO ESENCIAL.
      </Content>

      <Content>
        ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN O LIMITACIÓN DE CIERTOS
        DAÑOS, POR LO QUE ALGUNAS O TODAS LAS EXCLUSIONES Y LIMITACIONES EN ESTA
        SECCIÓN PUEDEN NO APLICARSE A USTED.
      </Content>

      <SubTitle>
        Manejo de Disputas a Través de Arbitraje o el Tribunal de Reclamaciones
        Menores
      </SubTitle>
      <Content>Excepto donde esté prohibido por la ley aplicable:</Content>

      <Content>
        El medio exclusivo para resolver cualquier disputa o reclamo que surja
        de o esté relacionado con este Acuerdo (incluyendo cualquier presunto
        incumplimiento del mismo), o el Servicio, será el ARBITRAJE VINCULANTE
        administrado por la Ley de Arbitraje de México, o a través del Tribunal
        de Reclamaciones Menores regido por las leyes mexicanas. Pero ya sea que
        elija el arbitraje o el tribunal de reclamaciones menores, usted acepta
        que no iniciará, mantendrá ni participará bajo ninguna circunstancia en
        ninguna acción colectiva, arbitraje colectivo u otra acción o
        procedimiento representativo contra Petmate.
      </Content>

      <Content>
        Al aceptar este Acuerdo, usted acepta el Acuerdo de Arbitraje en esta
        Sección 15. Al hacerlo, TANTO USTED COMO Petmate RENUNCIAN AL DERECHO DE
        IR A LOS TRIBUNALES para hacer valer o defender cualquier reclamo entre
        usted y Petmate (excepto en asuntos que puedan ser llevados
        apropiadamente a un tribunal de reclamaciones menores y estén dentro de
        la jurisdicción de dicho tribunal). USTED TAMBIÉN RENUNCIA A SU DERECHO
        DE PARTICIPAR EN UNA ACCIÓN COLECTIVA U OTRO PROCEDIMIENTO COLECTIVO,
        incluyendo, sin limitación, cualquier acción colectiva pasada, pendiente
        o futura.
      </Content>

      <SubTitle>Ley Aplicable</SubTitle>
      <Content>
        Excepto donde nuestro acuerdo de arbitraje esté prohibido por la ley,
        las leyes de México, sin considerar sus reglas sobre conflictos de
        leyes, se aplicarán a cualquier disputa que surja de o esté relacionada
        con este Acuerdo, el Servicio, o su relación con Petmate. No obstante lo
        anterior, la Ley de Arbitraje de México mencionada en la Sección 15
        anterior estará regida por la Ley de Arbitraje de México.
      </Content>

      <SubTitle>Jurisdicción</SubTitle>
      <Content>
        Excepto donde nuestro acuerdo de arbitraje esté prohibido por la ley,
        las leyes de México, sin considerar sus reglas sobre conflictos de
        leyes, se aplicarán a cualquier disputa que surja de o esté relacionada
        con este Acuerdo, el Servicio, o su relación con Petmate. No obstante lo
        anterior, la Ley de Arbitraje de México mencionada en la Sección 15
        anterior estará regida por la Ley de Arbitraje de México.
      </Content>

      <SubTitle>Indemnización por su Parte</SubTitle>
      <Content>
        Usted acepta, en la medida permitida por la ley aplicable, indemnizar,
        defender y eximir de responsabilidad a Petmate, nuestras afiliadas, y a
        sus y nuestros respectivos oficiales, directores, agentes y empleados de
        y contra cualquier y todas las quejas, demandas, reclamaciones, daños,
        pérdidas, costos, responsabilidades y gastos, incluidos los honorarios
        de abogados, debido a, que surjan de, o estén relacionados de alguna
        manera con su acceso o uso del Servicio, su Contenido, o su
        incumplimiento de este Acuerdo.
      </Content>

      <SubTitle>Acuerdo Completo; Otros</SubTitle>
      <Content>
        Este Acuerdo, junto con la Política de Privacidad, los Consejos de
        Seguridad, y cualquier término que se le haya comunicado si compra o ha
        comprado características, productos o servicios adicionales que
        ofrecemos en el Servicio, contiene el acuerdo completo entre usted y
        Petmate con respecto a su relación con Petmate y el uso del Servicio. Si
        alguna disposición de este Acuerdo se considera inválida, el resto de
        este Acuerdo continuará en pleno vigor y efecto. La falta de ejercicio o
        ejecución por parte de Petmate de cualquier derecho o disposición de
        este Acuerdo no constituirá una renuncia a dicho derecho o disposición.
        Usted acepta que su cuenta de Petmate es intransferible y que todos sus
        derechos sobre su cuenta y su Contenido terminan con su fallecimiento.
        No se crea ninguna agencia, sociedad, empresa conjunta, relación
        fiduciaria u otra relación especial o empleo como resultado de este
        Acuerdo y usted no puede hacer ninguna representación en nombre de
        Petmate ni vincular a Petmate de ninguna manera.
      </Content>

      <SubTitle>Consejos de Seguridad</SubTitle>
      <Content>Consejos de Seguridad de Pawmates</Content>
      <Content>
        Conocer nuevas personas y mascotas es algo emocionante, pero siempre
        debes ser cauteloso al interactuar con personas o animales que no
        conoces. Usa tu mejor criterio y prioriza tu seguridad y la de tu
        mascota, ya sea que estés intercambiando los primeros mensajes o
        reuniéndote en persona. Aunque no puedes controlar las acciones de los
        demás o de sus mascotas, hay cosas que puedes hacer para ayudarte a
        mantenerte seguro durante tu experiencia en Pawmates.
      </Content>

      <SubTitle>Seguridad en Línea</SubTitle>
      <Content>Nunca Envies Dinero ni Compartas Información Financiera</Content>
      <Content>
        Nunca envíes dinero, especialmente mediante transferencias bancarias,
        incluso si la persona afirma estar en una emergencia. Enviar dinero
        mediante transferencia es como enviar efectivo: es casi imposible
        revertir la transacción o rastrear a dónde fue el dinero. Nunca
        compartas información que pueda ser utilizada para acceder a tus cuentas
        financieras. Si otro usuario te pide dinero, repórtalo a nosotros de
        inmediato.
      </Content>

      <SubTitle>Protege tu Información Personal</SubTitle>
      <Content>
        Nunca compartas información personal, como tu número de seguridad
        social, dirección de casa o trabajo, o detalles sobre tu rutina diaria
        (por ejemplo, que vas a un gimnasio determinado todos los lunes) con
        personas que no conoces. Si eres padre o madre, limita la información
        que compartes sobre tus hijos en tu perfil y en las primeras
        comunicaciones. Evita compartir detalles como los nombres de tus hijos,
        dónde van a la escuela, o sus edades o géneros.
      </Content>

      <SubTitle>Mantente en la Plataforma</SubTitle>
      <Content>
        Mantén las conversaciones en la plataforma de Pawmates mientras estás
        conociendo a alguien. Los usuarios con malas intenciones a menudo
        intentan mover la conversación a mensajes de texto, aplicaciones de
        mensajería, correo electrónico o llamadas telefónicas de inmediato.
      </Content>

      <SubTitle>
        Ten Cuidado con las Relaciones a Larga Distancia y en el Extranjero
      </SubTitle>
      <Content>
        Ten cuidado con los estafadores que afirman ser de tu país pero que
        están atrapados en algún otro lugar, especialmente si piden ayuda
        financiera para regresar a casa. Desconfía de cualquiera que no quiera
        reunirse en persona o hablar por teléfono/llamada de video, ya que
        podrían no ser quienes dicen ser. Si alguien evita responder tus
        preguntas o insiste en tener una relación seria sin haberte conocido o
        sin conocerte bien primero, eso es una señal de alerta.
      </Content>

      <SubTitle>Informa sobre Comportamientos Sospechosos y Ofensivos</SubTitle>
      <Content>
        Sabes cuándo alguien ha cruzado la línea, y cuando lo hacen, queremos
        saberlo. Bloquea e informa a cualquier persona que viole nuestros
        términos. Aquí tienes algunos ejemplos de violaciones:
      </Content>

      <Content>• Solicitudes de dinero o donaciones.</Content>
      <Content>• Usuarios menores de edad.</Content>
      <Content>• Acoso, amenazas y mensajes ofensivos.</Content>
      <Content>
        • Comportamiento inapropiado o dañino durante o después de una reunión
        en persona.
      </Content>
      <Content>• Perfiles fraudulentos.</Content>
      <Content>
        • Spam o solicitudes, incluyendo enlaces a sitios web comerciales o
        intentos de vender productos o servicios.
      </Content>

      <Content>
        Puedes informar sobre cualquier preocupación acerca de comportamientos
        sospechosos desde cualquier página de perfil o ventana de mensajería, o
        enviar un correo electrónico a{" "}
        <Link href="mailto:petmateapp24@gmail.com">petmateapp24@gmail.com</Link>
        .
      </Content>

      <SubTitle>Protege tu Cuenta</SubTitle>
      <Content>
        Asegúrate de elegir una contraseña fuerte y siempre ten cuidado al
        iniciar sesión en tu cuenta desde una computadora pública o compartida.
        Petmate nunca te enviará un correo electrónico pidiéndote tu nombre de
        usuario y contraseña; si recibes un correo electrónico solicitando
        información de tu cuenta, repórtalo de inmediato.
      </Content>

      <SubTitle>Reuniones en Persona</SubTitle>
      <Content>
        Cuando decidas conocer a alguien en persona, elige un lugar público y
        seguro para la primera reunión, como un café, un parque concurrido o una
        plaza. Informa a un amigo o familiar sobre tu plan, incluyendo la
        ubicación y la hora de la reunión. Mantén tu teléfono cargado y a mano
        en todo momento. Confía en tu intuición: si algo no se siente bien, no
        dudes en cancelar o finalizar la reunión. Además, asegúrate de observar
        cómo interactúa la otra persona con tu mascota y de que ambos se sientan
        cómodos y seguros durante el encuentro.
      </Content>

      <SubTitle>No Te Apresures</SubTitle>
      <Content>
        Tómate tu tiempo para conocer a la otra persona antes de acordar
        reunirte o hablar fuera de Pawmates. No tengas miedo de hacer preguntas
        para detectar señales de alerta o aspectos personales que puedan ser
        decisivos para ti. Una llamada telefónica o por video puede ser una
        herramienta útil para filtrar antes de reunirse en persona.
      </Content>

      <SubTitle>
        Reúnete en Lugares Públicos y Permanece en Lugares Públicos
      </SubTitle>
      <Content>
        Reúnete las primeras veces en un lugar público y concurrido — nunca en
        tu casa, en la casa de tu cita, ni en ningún otro lugar privado. Si tu
        cita te presiona para ir a un lugar privado, termina la cita.
      </Content>

      <SubTitle>Informa a Amigos y Familiares sobre tus Planes</SubTitle>
      <Content>
        Antes de reunirte con alguien por primera vez, asegúrate de contarle a
        un amigo o familiar sobre tus planes. Comparte detalles como la hora, el
        lugar de la reunión y con quién te vas a encontrar. Considera enviarles
        tu ubicación en tiempo real utilizando aplicaciones de mensajería.
        Mantente en contacto durante la cita para que sepan que estás seguro.
      </Content>

      <SubTitle>Gestiona tu Seguridad y la de tu Mascota</SubTitle>
      <Content>
        Entiende que algunas mascotas tienen más energía, juegan de manera
        diferente o pueden ser más peligrosas que otras. Algunas pueden no ser
        amigables en absoluto, especialmente con nuevas personas y animales. Ten
        cuidado al conocer nuevos animales, y asegúrate de que el dueño diga que
        está bien antes de tocarlos o permitir que interactúen con tu mascota.
        Mantén una vigilancia cercana sobre tus mascotas en todo momento,
        especialmente cuando estés en un lugar público o en un lugar que pueda
        ser peligroso para tu mascota (por ejemplo, cerca del tráfico, en sitios
        de construcción, etc.). Nunca permitas que otra persona se vaya a algún
        lugar con tu mascota sin que tú estés presente. Usa tu sentido común y
        buen juicio en todo momento.
      </Content>

      <SubTitle>Controla tu Medio de Transporte</SubTitle>
      <Content>
        Te sugerimos que tengas el control de cómo llegas y te retiras de tu
        cita para que puedas irte cuando lo desees. Si vas conduciendo, es una
        buena idea tener un plan alternativo, como una aplicación de transporte
        compartido o un amigo que pueda recogerte.
      </Content>

      <SubTitle>Conoce tus Límites</SubTitle>
      <Content>
        Sé consciente de los efectos que las drogas o el alcohol tienen en ti
        específicamente, ya que pueden afectar tu juicio y tu nivel de alerta.
        Si tu cita intenta presionarte para que consumas drogas o bebas más de
        lo que te sientes cómodo, mantente firme y termina la cita.
      </Content>

      <SubTitle>
        No Dejes Bebidas ni Objetos Personales sin Supervisión
      </SubTitle>
      <Content>
        Sabe de dónde proviene tu bebida y manténla a la vista en todo momento;
        solo acepta bebidas servidas directamente por el bartender o el mesero.
        Muchas sustancias que se introducen en las bebidas para facilitar
        agresiones sexuales son inodoras, incoloras e insípidas. Además, mantén
        tu teléfono, bolso, cartera y cualquier cosa que contenga información
        personal contigo en todo momento.
      </Content>

      <SubTitle>Si Te Sientes Incómodo, Vete</SubTitle>
      <Content>
        Está bien terminar la cita temprano si te sientes incómodo. De hecho, se
        recomienda hacerlo. Y si tus instintos te dicen que algo no está bien o
        te sientes inseguro, pide ayuda al bartender o al mesero.
      </Content>

      <SubTitle>Viajes LGBTQ+ Ten Cuidado al Viajar</SubTitle>
      <Content>
        Reconocemos y creemos en la importancia de ser inclusivos con todas las
        identidades de género y orientaciones sexuales, pero la realidad es
        esta: en ninguna parte del mundo está exento de posibles riesgos, y
        algunos países tienen leyes específicas que afectan a las personas
        LGBTQ+.
      </Content>

      <Content>
        Consulta las leyes locales cuando viajes a un nuevo lugar e investiga
        qué tipos de protección legal, si es que existe alguna, están
        disponibles para ti en función de tu orientación sexual. En caso de que
        te encuentres en un territorio inseguro, te sugerimos desactivar la
        opción “Mostrarme en Barkerchat”, que puedes encontrar en la página de
        configuración.
      </Content>

      <Content>
        Si has añadido una orientación sexual a tu perfil y decides ser visible
        en Pawmates, ocultaremos tu orientación sexual en tu perfil hasta que
        salgas de esa área.
      </Content>

      <Content>
        Es importante que ejerzas una precaución adicional si decides conectarte
        con nuevas personas en estos países, ya que se ha sabido que algunas
        fuerzas del orden utilizan aplicaciones de citas como herramientas para
        posibles trampas. Algunos países también han introducido recientemente
        leyes que criminalizan las comunicaciones entre personas en aplicaciones
        o sitios web de citas del mismo sexo e incluso agravan las penas si esa
        comunicación conduce a encuentros sexuales.
      </Content>

      <Content>
        Visita ILGA World para ver las leyes más recientes sobre orientación
        sexual por país.
      </Content>

      <Content>Fuente: ILGA World, Actualizado en marzo de 2019</Content>

      <SubTitle>Salud Sexual y Consentimiento</SubTitle>
      <Content>
        Tu salud sexual es importante, y el consentimiento es fundamental en
        cualquier relación. Antes de participar en cualquier actividad sexual,
        asegúrate de que ambas partes estén de acuerdo y de que se haya dado un
        consentimiento claro y mutuo. Es esencial tener conversaciones abiertas
        y honestas sobre las expectativas, los límites y la protección antes de
        cualquier encuentro. Usa siempre protección para reducir el riesgo de
        enfermedades de transmisión sexual (ETS) y asegúrate de sentirte cómodo
        y seguro en todo momento. Recuerda, el consentimiento puede ser retirado
        en cualquier momento, y nadie debe sentirse presionado a hacer algo con
        lo que no se sienta bien.
      </Content>

      <SubTitle>Protégete</SubTitle>
      <Content>
        Cuando se usan correctamente y de manera consistente, los condones
        pueden reducir significativamente el riesgo de contraer y transmitir
        infecciones de transmisión sexual (ITS) como el VIH. Sin embargo, ten en
        cuenta que algunas ITS como el herpes o el VPH pueden transmitirse a
        través del contacto piel a piel. El riesgo de contraer algunas ITS puede
        reducirse mediante la vacunación.
      </Content>

      <SubTitle>Conoce tu Estado de Salud</SubTitle>
      <Content>
        No todas las ITS muestran síntomas, y no querrás estar en la oscuridad
        sobre tu estado. Mantén el control de tu salud y prevén la propagación
        de ITS haciéndote pruebas regularmente.
      </Content>

      <SubTitle>Háblalo</SubTitle>
      <Content>
        La comunicación lo es todo: antes de tener intimidad física con una
        pareja, hablen sobre la salud sexual y las pruebas de ITS. Y ten en
        cuenta que, en algunos lugares, es un delito transmitir conscientemente
        una ITS. ¿Necesitas ayuda para iniciar la conversación?
      </Content>

      <SubTitle>Contáctanos</SubTitle>
      <Content>
        Si tienes alguna pregunta sobre nuestras prácticas de privacidad o esta
        Política de Privacidad, o para ejercer tus derechos como se detalla en
        esta Política de Privacidad, por favor contáctanos en:
      </Content>
      <Content>
        <Link href="mailto:petmateapp24@gmail.com">petmateapp24@gmail.com</Link>
      </Content>
    </MainContainer>
  );
};

export default TermsAndConditions;
