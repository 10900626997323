import styled from "styled-components";

/**
 * Styled components
 */
const GradientSection = styled.section`
  height: 588px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  color: white;
  background: radial-gradient(circle, #07c7f3, #0094b7);
  padding-top: 42px;
  padding-bottom: 0px;
`;

const SectionContent = styled.section`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;

const RowSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;

  max-width: 100%;
  background-color: #fff;
`;

const GradientText = styled.h1`
  font-size: 36px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #fff200;
  text-align: left;
`;

const SubTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  text-align: left;
`;

const GradientSubText = styled.p`
  font-size: 18px;
`;

export { GradientSection, SectionContent, Title, SubTitle, RowSection };
