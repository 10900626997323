import styled from "styled-components";

/**
 * Styled components
 */
const MainContainer = styled.div`
  height: auto;
  width: "100%";
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: white;
  padding: 20px 80px 20px 80px;
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 500;
  color: #00acd4;
  text-align: left;
`;

const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: #3f4a4e;
  text-align: left;
`;

const Content = styled.h3`
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #82949b;
  line-height: 24px;
`;

const StyledTable = styled.table`
  min-width: 100%;
  border: 1px solid #d1d5db;
  text-align: left;
  font-size: 0.875rem; /* Tailwind's text-sm */
  color: #4b5563; /* Tailwind's gray-600 */
`;

const TableHead = styled.thead`
  background-color: #f3f4f6; /* Tailwind's gray-100 */
`;

const TableRow = styled.tr``;

const TableHeader = styled.th`
  padding: 0.5rem 1rem; /* Tailwind's px-4 py-2 */
  border-bottom: 1px solid #d1d5db; /* Tailwind's border-b border-gray-300 */
  font-weight: 600; /* Tailwind's font-semibold */
`;

const TableBody = styled.tbody``;

const TableData = styled.td`
  padding: 0.5rem 1rem; /* Tailwind's px-4 py-2 */
  border-bottom: 1px solid #d1d5db; /* Tailwind's border-b border-gray-300 */
  font-weight: 500; /* Tailwind's font-medium for specific cells */
  color: "#4B5563"; /* Tailwind's gray-800 for highlighted text and gray-600 for others */
`;

const Link = styled.a`
  color: #00acd4;
  text-decoration: none;
  &:hover {
    color: #00acd4;
    text-decoration: underline;
  }
`;

export {
  MainContainer,
  Title,
  Content,
  SubTitle,
  StyledTable,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableData,
  Link,
};
