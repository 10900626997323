import styled from "styled-components";
const FooterWrapper = styled.footer`
  background-color: #f8f9fa;
  padding: 20px 0;
  text-align: center;
  font-size: 14px;
  color: #6c757d;
  width: 100%;
`;
const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const AppName = styled.h3`
  font-size: 18px;
  margin: 0;
  color: #343a40;
`;

const FooterLinks = styled.div`
  margin: 15px 0;
  a {
    color: #6c757d;
    margin: 0 10px;
    text-decoration: none;

    &:hover {
      color: #343a40;
      text-decoration: underline;
    }
  }
`;

const Copyright = styled.p`
  margin: 0;
  color: #6c757d;
`;

export { FooterWrapper, FooterContent, AppName, FooterLinks, Copyright };
