import React from "react";
import {
  MainContainer,
  Title,
  Content,
  SubTitle,
  StyledTable,
  TableHeader,
  TableHead,
  TableRow,
  TableBody,
  TableData,
  Link,
} from "../styles";
const CookiePolicy: React.FC = () => {
  return (
    <MainContainer>
      <Title>Política de cookies</Title>
      <Content>
        En PetMate, queremos ser claros y honestos sobre cómo recopilamos y
        procesamos datos sobre ti. Esta página está diseñada para informarte
        acerca de nuestras prácticas con respecto a las cookies y explicarte
        cómo puedes gestionarlas.
      </Content>
      <Title>
        ¿Ya sabes todo lo necesario sobre las cookies y solo deseas modificar
        tus ajustes?
      </Title>
      <Content>
        No hay problema. Ingresa en los ajustes del perfil en PetMate para
        actualizar los ajustes de cookies del sitio web y dirígete a los ajustes
        de tu cuenta en la app para modificar tus preferencias de privacidad
        desde allí.
      </Content>
      <Title>
        ¿Quieres obtener más información sobre las cookies y cómo las
        utilizamos?
      </Title>
      <Content>
        ¡Con gusto te explicamos! Sigue leyendo. Nota: esta Política de cookies
        no aborda cómo procesamos tu información personal cuando no está
        relacionada con el uso que hacemos de las cookies. Para obtener más
        información sobre cómo procesamos tu información personal, consulta
        nuestra Política de privacidad.
      </Content>
      <Title>¿Qué son las cookies?</Title>
      <Content>
        Las cookies son pequeños archivos de texto que se envían a tu navegador
        web o la memoria de tu dispositivo o a los que se accede desde ellos.
        Una cookie suele incluir el nombre del dominio (la ubicación en
        Internet), que corresponde a su origen; su “tiempo de vida” (es decir,
        cuándo vence) y un número único o identificador similar generado de
        forma aleatoria. Una cookie también puede contener información sobre tu
        dispositivo, como preferencias de usuario, historial de navegación y las
        actividades realizadas mientras usas nuestros servicios.
      </Content>
      <Title>¿Existen diferentes tipos de cookies?</Title>
      <SubTitle>Cookies propias y de terceros</SubTitle>
      <Content>
        Existen cookies propias y de terceros. Nosotros colocamos las cookies
        propias en tu dispositivo directamente. Por ejemplo, utilizamos cookies
        propias para adaptar nuestro sitio web a las preferencias de idioma de
        tu navegador y para comprender mejor cómo utilizas nuestro sitio web.
        Nuestros socios y proveedores de servicios colocan las cookies de
        terceros en tu dispositivo. Puedes obtener más información sobre estos
        socios y proveedores de servicios a través de nuestro sitio web y las
        herramientas de gestión de consentimiento en la app. Para conocer más
        detalles sobre estas herramientas, consulta "¿Cómo puedo controlar las
        cookies?" a continuación.
      </Content>
      <SubTitle>Cookies de sesión y persistentes</SubTitle>
      <Content>
        Existen cookies de sesión y cookies persistentes. Las cookies de sesión
        solamente funcionan hasta que cierras tu navegador. Utilizamos cookies
        de sesión por varias razones, entre ellas, para comprender mejor cómo
        utilizas nuestro sitio web durante una sesión única de navegación y para
        ayudarte a utilizar nuestro sitio de forma más eficiente. Las cookies
        persistentes tienen un tiempo de vida más largo y continúan después de
        que tu sesión termina. Este tipo de cookies pueden utilizarse para
        ayudarte a volver a ingresar a nuestro sitio de manera más rápida, para
        propósitos de análisis y otras razones que explicamos a continuación.
      </Content>

      <Title>
        ¿Qué sucede con las demás tecnologías de rastreo, como las balizas web y
        los SDK?
      </Title>
      <Content>
        Otras tecnologías, como las balizas web (también conocidas como píxeles,
        etiquetas o gifs transparentes), URL de seguimiento o kits de desarrollo
        de software (SDK), se utilizan para fines similares a los de las
        cookies. Las balizas web son archivos gráficos pequeños que contienen un
        identificador único y nos permiten detectar cuando alguien ha visitado
        nuestro servicio o abierto un correo electrónico que le enviamos. Las
        URL de seguimiento son enlaces especialmente generados que nos ayudan a
        entender de dónde proviene el tráfico de nuestros sitios web. Los SDK
        son pequeñas partes de código incluidas en las apps que funcionan como
        cookies y balizas web. Por motivos de simplicidad, también nos referimos
        a estas tecnologías como “cookies” en esta Política de cookies.
      </Content>
      <Title>¿Para qué utilizamos cookies?</Title>
      <Content>
        Al igual que otros proveedores de servicios en línea, utilizamos cookies
        para prestar, proteger y mejorar nuestros servicios, lo que incluye
        recordar tus preferencias, detectar cuándo visitas nuestro sitio web,
        medir el éxito de nuestras campañas de marketing y personalizar y
        adaptar los anuncios con base en tus intereses. Para lograr esto,
        también es posible que vinculemos información de cookies con información
        personal adicional que tenemos sobre ti. Cuando utilices nuestros
        servicios, es posible que se instalen en tu dispositivo algunos o todos
        los tipos de cookies que se indican a continuación.
      </Content>

      <StyledTable>
        <TableHead>
          <TableRow>
            <TableHeader>Tipo de cookie</TableHeader>
            <TableHeader>Descripción</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableData>Cookies esenciales</TableData>
            <TableData>
              Estas cookies son estrictamente necesarias para prestarte nuestros
              servicios. Te permiten iniciar sesión, recuerdan tus preferencias
              y te mantienen seguro mediante la detección de actividades
              ilícitas.
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Cookies analíticas</TableData>
            <TableData>
              Estas cookies nos permiten entender cómo se utilizan nuestros
              servicios y nos ayudan a personalizar y mejorar nuestros servicios
              para ti.
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Cookies de publicidad y marketing</TableData>
            <TableData>
              Estas cookies se utilizan para determinar la eficacia de nuestras
              campañas de publicidad e incrementan la relevancia de los anuncios
              que ves. Sus funciones incluyen ayudarnos a entender cuánto
              tráfico trajeron nuestras campañas de publicidad a nuestros
              servicios, evitar que el mismo anuncio aparezca constantemente,
              garantizar que los anuncios se muestren de manera correcta para
              los anunciantes, seleccionar anuncios relevantes para ti y medir
              el número de anuncios mostrados y su desempeño (por ejemplo,
              cuántas personas interactuaron con un anuncio específico).
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Cookies de redes sociales</TableData>
            <TableData>
              Estas cookies se utilizan para permitirte compartir sitios web y
              contenido de nuestros servicios que te parezca interesante, a
              través de redes sociales de terceros y otros sitios web o
              servicios. Estas cookies también se pueden utilizar con fines
              publicitarios.
            </TableData>
          </TableRow>
        </TableBody>
      </StyledTable>
      <Title>¿Cómo puedes controlar las cookies?</Title>
      <Content>
        Hay varias opciones de gestión de cookies disponibles para ti. Ten en
        cuenta que los cambios que implementes a tus preferencias de cookies
        pueden ocasionar una experiencia menos satisfactoria con nuestros
        servicios, ya que podrían hacer que sean menos personalizados. En
        algunos casos, incluso es posible que no puedas utilizar nuestros
        servicios de forma total o parcial.
      </Content>
      <SubTitle>Herramientas que proporcionamos</SubTitle>
      <Content>
        Puedes establecer y ajustar tus preferencias sobre uso de cookies en
        cualquier momento. Para ello, utiliza la herramienta disponible en
        nuestro sitio web o dirígete a los ajustes de tu cuenta en la app para
        hacerlo desde allí.
      </Content>
      <SubTitle>Controles de navegador y dispositivos</SubTitle>
      <Content>
        Algunos navegadores web incluyen opciones de preferencias que te
        permiten controlar o rechazar cookies, o que te alertan cuando se
        establece una cookie en tu computadora. El procedimiento de gestión de
        cookies varía según el navegador de Internet. Puedes verificar los pasos
        específicos en el menú de ayuda de tu navegador. También puedes utilizar
        los ajustes correspondientes en tu dispositivo móvil para restablecer
        los identificadores de dispositivo o desactivar la recopilación y el
        procesamiento de identificadores. Los procedimientos para gestionar
        identificadores son ligeramente diferentes para cada dispositivo. Puedes
        verificar los pasos específicos en el menú de ayuda o el menú de ajustes
        de tu dispositivo.
      </Content>
      <SubTitle>Herramientas de publicidad con base en intereses</SubTitle>
      <Content>
        Las empresas de publicidad pueden participar en programas de
        autorregulación que te permiten elegir no recibir sus anuncios con base
        en intereses. Para obtener más información sobre este tema, puedes
        visitar los siguientes sitios: Alianza de Publicidad Digital, Alianza de
        Publicidad Digital Interactiva y Appchoices (solo apps). Si eliges no
        recibir anuncios con base en intereses, esto no significa que no verás
        anuncios publicitarios, sino que no verás anuncios personalizados de las
        empresas que participan en los programas que ofrecen esta opción.
        Además, si eliminas las cookies de tu dispositivo después de elegir esta
        opción, deberás desactivar la opción en cuestión nuevamente.
      </Content>
      <Title>Cookies de Google™</Title>
      <SubTitle>Cookies de la API de Google™ Maps</SubTitle>
      <Content>
        Cookies de la API de Google™ Maps Algunas funciones de nuestro sitio web
        y algunos servicios de PetMate se basan en el uso de las cookies de la
        API de Google™ Maps. Estas cookies se almacenarán en tu dispositivo. Al
        navegar por este sitio web y utilizar los servicios que dependen de las
        cookies de la API de Google™ Maps, autorizas el almacenamiento y la
        recopilación de este tipo de cookies en tu dispositivo, así como el
        acceso, el uso y la distribución por parte de Google de los datos
        recopilados de esta manera. Google™ gestiona la información y tus
        opciones en relación con las cookies de la API de Google™ Maps a través
        de una interfaz separada de la que proporciona tu navegador. Para
        obtener más información, consulta{" "}
        <Link href="https://policies.google.com/technologies/cookies">
          cómo Google usa las cookies
        </Link>
      </Content>
      <SubTitle>Google Analytics</SubTitle>
      <Content>
        Utilizamos Google Analytics, un servicio de Google que utiliza cookies y
        otras tecnologías de recopilación de datos, para recopilar información
        acerca del uso que haces del sitio web y de los servicios con el fin de
        informar tendencias del sitio web. Para obtener más información sobre
        cómo Google recopila y procesa datos, visita la página de
        <Link href="https://policies.google.com/privacy">
          {" "}
          Privacidad y Términos de Google
        </Link>
        . Si deseas inhabilitar Google Analytics, puedes descargar el{" "}
        <Link href="https://tools.google.com/dlpage/gaoptout?hl=es-419">
          complemento de desactivación de Google Analytics para navegadores
        </Link>{" "}
        e
        <Link href="https://myadcenter.google.com/home?sasb=true&ref=ad-settings">
          inhabilitar la personalización de anuncios de Google
        </Link>
        .
      </Content>
      <Title>¿Cómo contactarnos?</Title>
      <Content>
        Si tienes preguntas sobre esta Política de cookies, puedes comunicarte
        con nosotros via email en:{" "}
        <Link href="mailto:petmateapp24@gmail.com">petmateapp24@gmail.com</Link>
      </Content>
    </MainContainer>
  );
};

export default CookiePolicy;
