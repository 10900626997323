import React from "react";
import {
  MainContainer,
  Title,
  SubTitle,
  Content,
  Link,
  StyledTable,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableData,
} from "../styles";

const PrivacyPolicy: React.FC = () => {
  return (
    <MainContainer>
      <Title>Política de Privacidad</Title>
      <SubTitle>ALCANCE</SubTitle>
      <Content>
        Esta Política de Privacidad se aplica a la información personal que
        podemos procesar, incluyendo en nuestros sitios web, aplicaciones
        móviles y otras of a ertas en línea o fuera de línea. Para hacer que
        esta Política de Privacidad sea más fácil de leer, nuestros sitios web,
        aplicaciones móviles y otras ofertas se denominan colectivamente
        “Servicios.”
      </Content>

      <SubTitle>INFORMACIÓN PERSONAL QUE RECOPILAMOS</SubTitle>
      <Content>
        Las categorías de información personal que recopilamos dependen de cómo
        interactúas con nosotros, nuestros Servicios y los requisitos de la ley
        aplicable. Recopilamos la información que nos proporcionas, la
        información que obtenemos automáticamente cuando utilizas nuestros
        Servicios y la información de otras fuentes, como servicios y
        organizaciones de terceros, según se describe a continuación.
      </Content>

      <SubTitle>A. Información que Nos Proporcionas Directamente</SubTitle>
      <Content>
        Podemos recopilar la siguiente información personal que nos
        proporcionas.
      </Content>

      <Content>
        <strong>Creación de Cuenta.</strong> Podemos recopilar información
        cuando creas una cuenta, como tu nombre, dirección de correo
        electrónico, código postal y contraseña.
      </Content>

      <Content>
        <strong>Información sobre la Mascota.</strong> Recopilamos información
        sobre tu mascota, como el nombre, la edad, la raza, la ubicación, la
        especie y las fotos de tu mascota. También podemos recopilar información
        adicional que nos ayude a mejorar el servicio de maneras más
        personalizadas. Además, podemos recopilar cualquier información médica y
        de bienestar, información de cuidado y otra información sobre tu
        mascota.
      </Content>

      <Content>
        <strong>Compras.</strong> Podemos recopilar información personal y
        detalles asociados con tus compras, incluyendo información de pago.
        Cualquier pago realizado a través de nuestros Servicios es procesado por
        procesadores de pago de terceros. No recopilamos ni almacenamos
        directamente ninguna información de tarjetas de pago ingresada a través
        de nuestros Servicios, pero podemos recibir información asociada con tu
        tarjeta de pago (por ejemplo, tus detalles de facturación).
      </Content>

      <Content>
        <strong>Tus Comunicaciones con Nosotros.</strong> Podemos recopilar
        información personal, como dirección de correo electrónico, número de
        teléfono o dirección postal cuando solicitas información sobre nuestros
        Servicios, te registras en nuestro boletín informativo o programa de
        lealtad, solicitas soporte al cliente o técnico, solicitas un empleo o
        te comunicas con nosotros de alguna otra manera.
      </Content>

      <Content>
        <strong>Encuestas.</strong> Podemos contactarte para participar en
        encuestas. Si decides participar, se te puede pedir que proporciones
        cierta información que puede incluir información personal.
      </Content>

      <Content>
        <strong>Funciones Interactivas.</strong> Nosotros y otros que usan
        nuestros Servicios podemos recopilar información personal que envías o
        haces disponible a través de nuestras funciones interactivas (por
        ejemplo, grupos locales de mascotas, funciones de mensajería y chat,
        funcionalidades de comentarios, foros, blogs y páginas de redes
        sociales). Cualquier información que proporciones utilizando las
        funciones de intercambio público de los Servicios (referida aquí como
        “Contenido de Usuario”) será considerada “pública”, a menos que la ley
        aplicable disponga lo contrario, y no está sujeta a las protecciones de
        privacidad mencionadas en esta política. Por favor, ten precaución antes
        de revelar cualquier información que pueda identificarte en el mundo
        real a otros usuarios.
      </Content>

      <Content>
        <strong>Sorteos o Concursos.</strong> Podemos recopilar información
        personal que proporciones para cualquier sorteo o concurso que
        ofrezcamos. En algunas jurisdicciones, estamos obligados a compartir
        públicamente la información de los ganadores de sorteos y concursos.
      </Content>

      <Content>
        <strong>Conferencias, Ferias Comerciales y Otros Eventos.</strong>{" "}
        Podemos recopilar información personal de individuos cuando asistimos a
        conferencias, ferias comerciales y otros eventos.
      </Content>

      <Content>
        <strong>Desarrollo de Negocios y Alianzas Estratégicas.</strong> Podemos
        recopilar información personal de individuos y terceros para evaluar y
        perseguir posibles oportunidades de negocio.
      </Content>

      <Content>
        <strong>Solicitudes de Empleo.</strong> Podemos publicar ofertas de
        empleo y oportunidades en nuestros Servicios. Si respondes a una de
        estas publicaciones enviándonos tu solicitud, CV y/o carta de
        presentación, recopilaremos y utilizaremos tu información para evaluar
        tus cualificaciones.
      </Content>

      <SubTitle>B. Información Recopilada Automáticamente</SubTitle>
      <Content>
        Podemos recopilar información personal automáticamente cuando usas
        nuestros Servicios:
      </Content>

      <Content>
        <strong>Recopilación Automática de Datos.</strong> Podemos recopilar
        cierta información automáticamente cuando usas nuestros Servicios, como
        tu dirección de Protocolo de Internet (IP), configuraciones del usuario,
        dirección MAC, identificadores de cookies, operador móvil, publicidad
        móvil y otros identificadores únicos, información del navegador o
        dispositivo, información de ubicación (incluida la ubicación aproximada
        derivada de la dirección IP) y proveedor de servicios de Internet.
        También podemos recopilar automáticamente información sobre tu uso de
        nuestros Servicios, como las páginas que visitas antes, durante y
        después de usar nuestros Servicios, información sobre los enlaces en los
        que haces clic, los tipos de contenido con los que interactúas, la
        frecuencia y duración de tus actividades, y otra información sobre cómo
        usas nuestros Servicios. Además, podemos recopilar información que otras
        personas proporcionan sobre ti cuando usan nuestros Servicios,
        incluyendo información sobre ti cuando te etiquetan o te mencionan en
        una publicación en un foro.
      </Content>

      <Content>
        <strong>Información de Ubicación.</strong> Podemos recopilar información
        precisa de ubicación, como [insertar ejemplo] o utilizar nuestros
        Servicios de otra manera.
      </Content>

      <Content>
        <strong>
          Cookies, Etiquetas de Píxeles/Balizas Web y Otras Tecnologías.
        </strong>{" "}
        Nosotros, así como terceros que proporcionan contenido, publicidad u
        otras funcionalidades en nuestros Servicios, podemos usar cookies,
        etiquetas de píxeles, almacenamiento local y otras tecnologías
        (“Tecnologías”) para recopilar automáticamente información a través de
        tu uso de nuestros Servicios.
      </Content>

      <Content>
        <strong>Cookies.</strong> Las cookies son pequeños archivos de texto que
        se colocan en los navegadores de los dispositivos que almacenan
        preferencias y facilitan y mejoran tu experiencia.
      </Content>

      <Content>
        <strong>Etiquetas de Píxeles/Balizas Web.</strong> Una etiqueta de píxel
        (también conocida como baliza web) es un fragmento de código incrustado
        en nuestros Servicios que recopila información sobre la interacción con
        nuestros Servicios. El uso de una etiqueta de píxel nos permite
        registrar, por ejemplo, que un usuario ha visitado una página web en
        particular o ha hecho clic en un anuncio en particular. También podemos
        incluir balizas web en correos electrónicos para entender si los
        mensajes se han abierto, actuado sobre ellos, o reenviado.
      </Content>

      <Content>
        Nuestro uso de estas Tecnologías se divide en las siguientes categorías
        generales:
      </Content>

      <StyledTable>
        <TableHead>
          <TableRow>
            <TableHeader>Categoría</TableHeader>
            <TableHeader>Descripción</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableData>Operacionalmente Necesario</TableData>
            <TableData>
              Esto incluye Tecnologías que te permiten acceder a nuestros
              Servicios, aplicaciones y herramientas que son necesarias para
              identificar comportamientos irregulares en el sitio web, prevenir
              actividades fraudulentas y mejorar la seguridad, o que te permiten
              utilizar nuestra funcionalidad.
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Relacionado con el Rendimiento</TableData>
            <TableData>
              Podemos usar Tecnologías para evaluar el rendimiento de nuestros
              Servicios, incluyendo como parte de nuestras prácticas analíticas
              para ayudarnos a entender cómo los individuos usan nuestros
              Servicios (ver Análisis más abajo).
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Relacionado con la Funcionalidad</TableData>
            <TableData>
              Podemos usar Tecnologías que nos permiten ofrecerte una
              funcionalidad mejorada al acceder o usar nuestros Servicios. Esto
              puede incluir identificarte cuando inicias sesión en nuestros
              Servicios o mantener un seguimiento de tus preferencias
              específicas, intereses o artículos vistos anteriormente.
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Relacionado con Publicidad o Segmentación</TableData>
            <TableData>
              Podemos usar Tecnologías propias o de terceros para entregar
              contenido, incluyendo anuncios relevantes para tus intereses, en
              nuestros Servicios o en sitios web de terceros.
            </TableData>
          </TableRow>
        </TableBody>
      </StyledTable>

      <Content>
        Consulta “
        <Link href="#privacy-options">
          Tus Opciones y Derechos de Privacidad
        </Link>
        ” a continuación para comprender tus opciones con respecto a estas
        Tecnologías.
      </Content>

      <Content>
        <strong>Análisis.</strong> Podemos usar Tecnologías y otras herramientas
        de terceros para procesar información analítica en nuestros Servicios,
        como Google Analytics. Para más información, por favor visita la
        Política de Privacidad de Google Analytics. Para aprender más sobre cómo
        optar por no participar en el uso de tu información por parte de Google
        Analytics, haz clic en{" "}
        <Link href="https://tools.google.com/dlpage/gaoptout">este enlace</Link>
        .
      </Content>

      <Content>
        <strong>Plataformas de Redes Sociales.</strong> Nuestros Servicios
        pueden contener botones de redes sociales como Facebook (que pueden
        incluir widgets como el botón “compartir esto” u otros mini programas
        interactivos). Estas características pueden recopilar tu dirección IP,
        la página que estás visitando en nuestros Servicios y pueden establecer
        una cookie para permitir que la función funcione correctamente. Tus
        interacciones con estas plataformas se rigen por la política de
        privacidad de la empresa que las proporciona.
      </Content>

      {/* Continue the structure similarly for the remaining sections of the policy */}

      <SubTitle>CONTÁCTANOS</SubTitle>
      <Content>
        Si tienes alguna pregunta sobre nuestras prácticas de privacidad o esta
        Política de Privacidad, o para ejercer tus derechos como se detalla en
        esta Política de Privacidad, por favor contáctanos en:
      </Content>
      <Content>
        <Link href="mailto:petmateapp24@gmail.com">petmateapp24@gmail.com</Link>
      </Content>
    </MainContainer>
  );
};

export default PrivacyPolicy;
