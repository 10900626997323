//TODO: FIX SVGs imports
import {
  GradientSection,
  SectionContent,
  Title,
  SubTitle,
  RowSection,
} from "./styles";
// @ts-ignore
import Logo from "../../assets/LogoPM.svg";
// @ts-ignore
import PetsImage from "../../assets/img-mascotas-huellas.svg";
// @ts-ignore
import MobileScreens from "../../assets/pic-mobile-screens.svg";
// @ts-ignore
import RightPictures from "../../assets/pictures.svg";
// @ts-ignore
import IconCheck from "../../assets/icon-checkmark.svg";

const Home: React.FC = () => {
  return (
    <div>
      <GradientSection>
        <SectionContent style={{ paddingLeft: "100px" }}>
          <img src={Logo} alt="Logo" />
          <Title>Conectando mascotas, creando familias</Title>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              src={IconCheck}
              alt="IconCheck"
              style={{ marginRight: "24px" }}
            />
            <SubTitle>Busca la pareja perfecta para tu mascota</SubTitle>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              src={IconCheck}
              alt="IconCheck"
              style={{ marginRight: "24px" }}
            />
            <SubTitle>Adopta una mascota</SubTitle>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              src={IconCheck}
              alt="IconCheck"
              style={{ marginRight: "24px" }}
            />
            <SubTitle>Programa encuentros para tu mascota</SubTitle>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              src={IconCheck}
              alt="IconCheck"
              style={{ marginRight: "24px" }}
            />
            <SubTitle>
              Encuentra veterinarias, estéticas, entrenadores, localiza parques,
              servicios pet friendly y más servicios relacionados
            </SubTitle>
          </div>
          <SubTitle style={{ textAlign: "center" }}>
            ¡Descarga nuestra app!
          </SubTitle>
          <Title style={{ textAlign: "center" }}>¡Proximamente!</Title>
        </SectionContent>
        <SectionContent style={{ justifyContent: "flex-end" }}>
          <img src={PetsImage} alt="PetsImage" />
        </SectionContent>
      </GradientSection>
      <RowSection>
        <img src={MobileScreens} alt="MobileScreens" />

        <img src={RightPictures} alt="RightPictures" />
      </RowSection>
    </div>
  );
};

export default Home;
