import {
  AppName,
  Copyright,
  FooterContent,
  FooterLinks,
  FooterWrapper,
} from "./styles";

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <AppName>Petmate</AppName>
        <FooterLinks>
          <a href="/terms-and-conditions">Terms and conditions</a>
          <a href="/cookie-policy">Coockies Policy</a>
          <a href="/privacy-policy">Privacy Policy</a>
        </FooterLinks>
        <Copyright>
          &copy; {new Date().getFullYear()} Petmate. All rights reserved.
        </Copyright>
      </FooterContent>
    </FooterWrapper>
  );
};

export default Footer;
